export class ModalConfig {
    width: Width;
    desktopAlign: DesktopAlign;
    mobileAlign: MobileAlign;
    scrollOrigin: ScrollOrigin;
    fullSize: boolean;
    mobileFullSize: boolean;
    animation: Animation;
    fromMenu: boolean;

    constructor(
        $width: Width = Width.normal,
        $desktopAlign: DesktopAlign = DesktopAlign.center,
        $mobileAlign: MobileAlign = MobileAlign.center,
        $scrollOrigin: ScrollOrigin = ScrollOrigin.page,
        $fullSize: boolean = false,
        $mobileFullSize: boolean = false,
        $animation: Animation = Animation.fadeIn,
        $fromMenu: boolean = false
    ) {
        this.width = $width;
        this.desktopAlign = $desktopAlign;
        this.mobileAlign = $mobileAlign;
        this.scrollOrigin = $scrollOrigin;
        this.fullSize = $fullSize;
        this.mobileFullSize = $mobileFullSize;
        this.animation = $animation;
        this.fromMenu = $fromMenu;
    }
}

export enum Width {
    normal = 'NORMAL',
    medium = 'MEDIUM',
    large = 'LARGE',
}

export enum DesktopAlign {
    top = 'TOP',
    center = 'CENTER',
    bottom = 'BOTTOM',
}

export enum MobileAlign {
    top = 'TOP',
    center = 'CENTER',
    bottom = 'BOTTOM',
}

export enum ScrollOrigin {
    page = 'PAGE',
    modal = 'MODAL',
}

export enum Animation {
    fadeIn = 'FADE_IN',
    fadeInUp = 'FADE_IN_UP',
    fadeInDown = 'FADE_IN_DOWN',
    zoomIn = 'ZOOM_IN',
}
