import { Injectable, Inject, ViewChild, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class SEOService {

    @ViewChild('canonical', { static: false }) canonical!: ElementRef;

    constructor(
        private title: Title,
        private meta: Meta,
        @Inject(DOCUMENT) private document: any
    ) { }


    updateTitle(title: string) {
        this.title.setTitle(title);
    }

    updateDescription(desc: string) {
        this.meta.updateTag({ name: 'description', content: desc });
    }

    updateOpenGraph(element: Record<string, string>) {
        this.meta.updateTag({ property: 'og:title', content: element.title });
        this.meta.updateTag({ property: 'og:description', content: element.description });
        this.meta.updateTag({ property: 'og:url', content: `${environment.apiUrl}/${(this.document.location.pathname).substring(1)}` });
        // tslint:disable-next-line: max-line-length
        this.meta.updateTag({ property: 'og:image', content: element.image || '' });
    }
}
