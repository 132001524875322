import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { SwUpdate } from '@angular/service-worker';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AnalyticsService } from './core/services/base/analytics.service';
import { SEOService } from './core/services/base/seo.service';
import { LocalStorageManagementService } from './core/services/base/local-storage-management.service';
import { ModalService } from './core/services/base/modal.service';
import { UtilsService } from './core/services/base/utils.service';

import { DataLayerCustomDimensions } from './core/data-layer/data-layer-custom-dimensions';

import { Animation, DesktopAlign, MobileAlign, ModalConfig, ScrollOrigin, Width } from './shared/components/modal/modal-config.model';

import { add, isAfter, isValid, parseISO } from 'date-fns';

import { VERSION } from '@env/version';

@Component({
    selector: 'afy-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

    public iOSPWAModalConfig!: ModalConfig;

    private routerSeoSubscription!: Subscription;

    constructor(
        private activatedRoute: ActivatedRoute,
        private analyticsService: AnalyticsService,
        private router: Router,
        private swUpdate: SwUpdate,
        private seoService: SEOService,
        private localStorageManagementService: LocalStorageManagementService,
        private deviceDetectorService: DeviceDetectorService,
        private modalService: ModalService,
        private utilsService: UtilsService,
        @Inject(DOCUMENT) private document: Document,
    ) { }

    ngOnInit(): void {

        console.log('version - ', VERSION.version);

        this.iOSPWAModalConfig = new ModalConfig(Width.normal, DesktopAlign.center, MobileAlign.center, ScrollOrigin.modal, false, false, Animation.fadeInUp, false);

        this.checkSwUpdate();
        this.setupAppVersion();

        this.routerSeoSubscription = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data)
        )
            .subscribe((event) => {
                if (event.dataLayer) {
                    event.dataLayer[DataLayerCustomDimensions.Page] = event.dataLayer.url || this.router.url;

                    if (event.dataLayerWaitForUpdate) {
                        this.analyticsService.updateCustomDimensions(event.dataLayer);
                    } else {
                        this.analyticsService.pageViewWithCustomDimensions(event.dataLayer);
                    }
                }

                this.seoService.updateTitle(event.title);
                this.seoService.updateDescription(event.description);
            });
    }


    ngAfterViewInit(): void {
        this.pwaInstallationModalManager();
    }

    //
    // ─── PWA METHODS ────────────────────────────────────────────────────────────────
    //


    checkSwUpdate(): void {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.unrecoverable.subscribe(() => {
                window.location.reload();
            })

            this.swUpdate.available.subscribe(() => {
                if (confirm('Nuova versione disponibile. Vuoi caricarla?')) {
                    window.location.reload();
                }
            });
        };
    }

    pwaInstallationModalManager(): void {
        const iOSPWAInstallationModal = this.localStorageManagementService.getIOSPWAInstallModal();
        const cookieBanner = this.document.getElementById('iubenda-cs-banner');
        let installationModalTimeExpired = false;

        if (this.localStorageManagementService.getIOSPWAInstallModal() !== null && !cookieBanner) {
            if (isValid(parseISO(iOSPWAInstallationModal.date)) && isAfter(new Date(), add(new Date(iOSPWAInstallationModal.date), { weeks: 1 }))) {
                installationModalTimeExpired = true;
            }
        } else {
            installationModalTimeExpired = true;
        }


        if (this.deviceDetectorService.os === 'iOS' && !this.utilsService.isInStandaloneMode() && installationModalTimeExpired) {

            this.modalService.open('iOSPWAInstall');
            this.localStorageManagementService.setIOSPWAInstallModal({ date: new Date() });
        }
    }

    //
    // ─── VERSIONS - METHODS ─────────────────────────────────────────────────────────
    //

    setupAppVersion(): void {
        const version = this.document.createElement('script');
        version.type = 'text/javascript';
        version.text = `window.appVersion = function() { return '${VERSION.version} - ${VERSION.hash}' }`;
        this.document.body.appendChild(version);
    }

    ngOnDestroy(): void {
        this.routerSeoSubscription.unsubscribe();
    }
}
