import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageType } from './core/data-layer/data-layer-custom-dimensions';

import { AuthGuard } from './core/guards/auth.guard';

import { LayoutLoginPageComponent } from './shared/layouts/layout-login-page.component';
import { LayoutPagesComponent } from './shared/layouts/layout-pages.component';

const routes: Routes = [
    { path: '', redirectTo: 'news', pathMatch: 'full' },
    {
        path: '',
        component: LayoutLoginPageComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./features/auth/auth.module').then(
                        (m: typeof import('./features/auth/auth.module')) =>
                            m.AuthModule
                    ),
                data: {
                    dataLayer: {
                        pageType: PageType.Login,
                        pageName: 'login',
                    },
                },
            },
        ],
    },
    {
        path: '',
        component: LayoutPagesComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'news',
                loadChildren: () =>
                    import('./features/news/news.module').then(
                        (m: typeof import('./features/news/news.module')) =>
                            m.NewsModule
                    ),
                data: {
                    pageName: 'news',
                    title: 'Notizie | Hi-Fed!',
                    description: 'Notizie',
                    dataLayer: {
                        pageType: PageType.News,
                        pageName: 'news',
                    },
                },
            },
            {
                path: 'events',
                loadChildren: () =>
                    import('./features/events/events.module').then(
                        (m: typeof import('./features/events/events.module')) =>
                            m.EventsModule
                    ),
                data: {
                    pageName: 'events',
                    title: 'Eventi | Hi-Fed!',
                    description: 'Eventi',
                    dataLayer: {
                        pageType: PageType.Events,
                        pageName: 'events',
                    },
                },
            },
            {
                path: 'notifications',
                loadChildren: () =>
                    import(
                        './features/notifications/notifications.module'
                    ).then(
                        (
                            m: typeof import('./features/notifications/notifications.module')
                        ) => m.NotificationsModule
                    ),
                data: {
                    pageName: 'notifications',
                    title: 'Notifiche | Hi-Fed!',
                    description: 'Notifiche',
                    dataLayer: {
                        pageType: PageType.Notifications,
                        pageName: 'notifications',
                    },
                },
            },
            {
                path: 'surveys',
                loadChildren: () =>
                    import('./features/surveys/surveys.module').then(
                        (
                            m: typeof import('./features/surveys/surveys.module')
                        ) => m.SurveysModule
                    ),
                data: {
                    pageName: 'surveys',
                    title: 'Sondaggi | Hi-Fed!',
                    description: 'Sondaggi',
                    dataLayer: {
                        pageType: PageType.Surveys,
                        pageName: 'surveys',
                    },
                },
            },
            {
                path: 'wiki',
                loadChildren: () =>
                    import('./features/wiki/wiki.module').then(
                        (m: typeof import('./features/wiki/wiki.module')) =>
                            m.WikiModule
                    ),
                data: {
                    pageName: 'wiki',
                    title: 'Wiki | Hi-Fed!',
                    description: 'Wiki',
                    dataLayer: {
                        pageType: PageType.Wiki,
                        pageName: 'wiki',
                    },
                },
            },
            {
                path: 'bookings',
                loadChildren: () =>
                    import('@worthspace/booking').then(
                        (m: typeof import('@worthspace/booking')) =>
                            m.BookingsModule
                    ),
                data: {
                    pageName: 'bookings',
                    title: 'Prenota | Hi-Fed!',
                    description: 'Prenota',
                    dataLayer: {
                        pageType: PageType.Bookings,
                        pageName: 'Bookings',
                    },
                },
            },
        ],
    },
    { path: '**', redirectTo: 'news' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
