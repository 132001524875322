import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { FullCustomDimensionsSet } from '@app/core/data-layer/full-custom-dimensions-set';
import { DataLayerCustomDimensions } from '@app/core/data-layer/data-layer-custom-dimensions';

// This is a type that can be used for a partial version of the dimensions set,
// Where there are gaps and some dimensions are empty
export type PartialCustomDimensionsSet = { [key: string]: string };

@Injectable({
    providedIn: 'root'
})
export class DataLayerCustomDimensionsService {
    private currentSet!: FullCustomDimensionsSet;
    private constantDimensions!: PartialCustomDimensionsSet;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        this.generateEmptyDimensionsSet();
        this.constantDimensions = {};
    }

    set dimensions(someDimensions: PartialCustomDimensionsSet) {
        Object.keys(DataLayerCustomDimensions).map((key: string) => DataLayerCustomDimensions[key as keyof typeof DataLayerCustomDimensions] as DataLayerCustomDimensions)
            .forEach((key) => {
                this.currentSet[key] = someDimensions[key] || this.constantDimensions[key];
            });
    }

    set onlySomeDimensions(someDimensions: PartialCustomDimensionsSet) {
        Object.keys(DataLayerCustomDimensions).map((key: string) => DataLayerCustomDimensions[key as keyof typeof DataLayerCustomDimensions] as DataLayerCustomDimensions)
            .forEach(key => {
                this.currentSet[key] = someDimensions[key] || this.constantDimensions[key] || this.currentSet[key];
            });
    }

    trigger() {
        if (isPlatformBrowser(this.platformId)) {
            (<any>window).dataLayer.push({
                event: 'historyChange',
                ...this.currentSet
            });
        }
    }

    private generateEmptyDimensionsSet() {
        this.currentSet = {
            [DataLayerCustomDimensions.Page]: undefined,
            [DataLayerCustomDimensions.PageType]: undefined,
            [DataLayerCustomDimensions.PageName]: undefined
        };
    }
}
