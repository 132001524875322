export enum DataLayerCustomDimensions {
    Page = 'page',
    PageType = 'pageType',
    PageName = 'pageName',
}

export enum DataLayerSpecificCustomDimensions {}

export enum PageType {
    News = 'news',
    Events = 'events',
    Notifications = 'notifications',
    Login = 'login',
    Surveys = 'surveys',
    Wiki = 'wiki',
    Bookings = 'bookings',
}
