import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { of, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from '@app/core/services/base/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.isTokenExpired()) {
            return this.authService.refreshToken().pipe(
                switchMap(() => of(true)),
                catchError(() => {
                    this.router.navigate(['/login']);
                    return of(false);
                })
            );
        } else {
            return true;
        }
    }
}
