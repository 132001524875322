import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { VERSION } from '@env/version';

if (environment.sentryEnabled) {
    Sentry.init({
        environment: environment.environment,
        release: 'hifed-fe@' + VERSION.version,
        dsn: environment.sentryDsn,
        beforeSend(event, hint) {
            if (hint?.originalException?.toString().includes('401')) {
                return null;
            }
            return event;
        },
    });
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
