<div class="afy-nav">
    <ul class="afy-nav__buttons">
        <li class="afy-nav__item -news" routerLinkActive="-current">
            <a class="afy-nav__link" [routerLink]="['/news']">
                <i class="afy-nav__icon icon-news"></i>
                <p class="afy-nav__text">Notizie</p>
            </a>
        </li>
        <li class="afy-nav__item -events" routerLinkActive="-current">
            <a class="afy-nav__link" [routerLink]="['/events']">
                <i class="afy-nav__icon icon-calendar"></i>
                <p class="afy-nav__text">Eventi</p>
            </a>
        </li>
        <li class="afy-nav__item -events" routerLinkActive="-current">
            <a class="afy-nav__link" [routerLink]="['/bookings']">
                <i class="afy-nav__icon icon-calendar"></i>
                <p class="afy-nav__text">Prenota</p>
            </a>
        </li>
        <li class="afy-nav__item -surveys" routerLinkActive="-current">
            <a class="afy-nav__link" [routerLink]="['/surveys']">
                <i class="afy-nav__icon icon-announcement-speaker"></i>
                <p class="afy-nav__text">Dí la tua</p>
            </a>
        </li>
        <li class="afy-nav__item -wiki" routerLinkActive="-current">
            <a class="afy-nav__link" [routerLink]="['/wiki']">
                <i class="afy-nav__icon icon-hamburger-menu"></i>
                <p class="afy-nav__text">Wiki</p>
            </a>
        </li>
    </ul>
</div>
