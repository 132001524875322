import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SwiperModule } from 'swiper/angular';


import { LayoutLoginPageComponent } from './layouts/layout-login-page.component';
import { LayoutPagesComponent } from './layouts/layout-pages.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { TagsSliderComponent } from './components/tags-slider/tags-slider.component';
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll.component';
import { ModalComponent } from './components/modal/modal.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';



@NgModule({
    declarations: [
        HeaderComponent,
        NavigationComponent,
        ToolbarComponent,
        TagsSliderComponent,
        LayoutLoginPageComponent,
        LayoutPagesComponent,
        InfiniteScrollComponent,
        ModalComponent,
        SafeHtmlPipe
    ],
    imports: [
        CommonModule,
        RouterModule,
        SwiperModule
    ],
    exports: [
        HeaderComponent,
        NavigationComponent,
        ToolbarComponent,
        TagsSliderComponent,
        LayoutLoginPageComponent,
        LayoutPagesComponent,
        InfiniteScrollComponent,
        ModalComponent,
        SafeHtmlPipe
    ]
})
export class SharedModule { }
