import { Injectable } from '@angular/core';

import { DataLayerCustomDimensionsService, PartialCustomDimensionsSet } from '@app/core/services/base/data-layer.service';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    constructor(
        private dataLayerCustomDimensions: DataLayerCustomDimensionsService,
    ) { }

    updateCustomDimensions(someDimensions: PartialCustomDimensionsSet) {
        this.dataLayerCustomDimensions.dimensions = someDimensions;
    }

    updateOnlySomeCustomDimensions(someDimensions: PartialCustomDimensionsSet) {
        this.dataLayerCustomDimensions.onlySomeDimensions = someDimensions;
    }

    event(someDimensions: PartialCustomDimensionsSet) {
        (<any>window).dataLayer.push(someDimensions);
    }

    pageView() {
        this.dataLayerCustomDimensions.trigger();
    }

    pageViewWithCustomDimensions(someDimensions: PartialCustomDimensionsSet) {
        this.updateCustomDimensions(someDimensions);
        this.pageView();
    }

    pageViewWithUpdateOnlySomeCustomDimention(someDimensions: PartialCustomDimensionsSet) {
        this.updateOnlySomeCustomDimensions(someDimensions);
        this.pageView();
    }
}
