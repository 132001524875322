export const environment = {
    production: true,
    apiUrl: 'https://api-worthspace.hnrg.it',
    boUrl: "https://bo-hi-fed.hnrg.it",
    serviceWorker: true,
    microsoftApplicationId: '790879b5-0f04-4640-9e46-977032f4bc62',
    microsoftAuthorizationUrl: 'https://login.microsoftonline.com/a248a8b3-ddc0-49da-9a70-70c77203e1d7/oauth2/v2.0/authorize',
    redirectPathname: 'https://hi-fed.alteafederation.it',
    environment: 'PROD',
    sentryEnabled: true,
    sentryDsn: "https://27a6124f7be2b9e2f518362de02d4add@sentry.hnrg.it/7",
};

