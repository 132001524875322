import { Component } from '@angular/core';

@Component({
    selector: 'afy-layout-pages',
    template: `
        <afy-header></afy-header>
        <router-outlet></router-outlet>
        <afy-navigation></afy-navigation>
    `,
    styles: []
})
export class LayoutPagesComponent {

    constructor() { }
}
