import { Injectable } from '@angular/core';

import { Constants } from '@app/core/constants/constants';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageManagementService {
    constructor() { }

    //
    // ─── GENERIC METHODS ────────────────────────────────────────────────────────────
    //

    getLocalStorage(): string {
        return JSON.stringify(localStorage);
    }

    getItem(item: string): string | null {
        return localStorage.getItem(item);
    }

    setItem(item: string, value: any): void {
        localStorage.setItem(item, value);
    }

    removeItem(item: string): void {
        localStorage.removeItem(item);
    }

    clearLocalStorage(): void {
        localStorage.clear();
    }

    //
    // ─── TOKEN METHODS ──────────────────────────────────────────────────────────────
    //

    getToken(): string | null {
        return localStorage.getItem(Constants.TOKEN);
    }

    setToken(token: string): void {
        localStorage.setItem(Constants.TOKEN, token);
    }

    getRefreshToken(): string | null {
        return localStorage.getItem(Constants.REFRESH_TOKEN);
    }

    setRefreshToken(token: string): void {
        localStorage.setItem(Constants.REFRESH_TOKEN, token);
    }

    deleteTokens(): void {
        localStorage.removeItem(Constants.TOKEN);
        localStorage.removeItem(Constants.REFRESH_TOKEN);
    }

    //
    // ─── PWA - METHODS ──────────────────────────────────────────────────────────────
    //

    getIOSPWAInstallModal(): any {
        return JSON.parse(localStorage.getItem(Constants.IOS_PWA_INSTALL_MODAL) as string) as any;
    }

    setIOSPWAInstallModal(show: any): void {
        localStorage.setItem(Constants.IOS_PWA_INSTALL_MODAL, JSON.stringify(show));
    }
}
