import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { AuthResponse } from '@app/shared/models/auth-response.model';

import { LocalStorageManagementService } from './local-storage-management.service';

import { fromUnixTime, differenceInMinutes } from 'date-fns';
import jwtDecode, { JwtPayload } from 'jwt-decode';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private basePath = 'hifed/';

    constructor(
        //
        private httpClient: HttpClient,
        private router: Router,
        private localStorageService: LocalStorageManagementService
    ) { }

    login(email: string, password: string): Observable<AuthResponse> {
        const url = 'login_msad';
        const params = {
            email,
            password,
        };

        return this.httpClient.post<AuthResponse>(url, params).pipe(
            tap((response: AuthResponse) => {
                this.localStorageService.setToken(response.token);
                this.localStorageService.setRefreshToken(
                    response.refresh_token
                );
            })
        );
    }

    loginWithToken(token: string): Observable<AuthResponse> {
        const url = 'login_msad';
        const params = {
            authorization_code: token,
        };

        return this.httpClient.post<AuthResponse>(url, params).pipe(
            tap((response: AuthResponse) => {
                this.localStorageService.setToken(response.token);
                this.localStorageService.setRefreshToken(
                    response.refresh_token
                )
            })
        );
    }

    logout(): Observable<any> {
        const url = `logout`;

        return this.httpClient.post<any>(url, {}).pipe(
            finalize(() => {
                this.localStorageService.deleteTokens()
                this.router.navigate(['/login']);
            })
        );
    }

    refreshToken(): Observable<AuthResponse> {
        const url = `token/refresh`;
        const params = {} as any;
        const refreshToken = this.localStorageService.getRefreshToken();

        if (refreshToken) {
            params['refresh_token'] = refreshToken;
        }

        return this.httpClient.post<AuthResponse>(url, params).pipe(
            tap((response: AuthResponse) => {
                this.localStorageService.setToken(response.token);
                if (response.refresh_token) {
                    this.localStorageService.setRefreshToken(
                        response.refresh_token
                    );
                }
            })
        );
    }

    getTokenExpirationDate(token: string): Date | null {
        try {
            const decoded = jwtDecode<JwtPayload>(token);

            if (decoded.exp === undefined) {
                return null;
            }

            return fromUnixTime(decoded.exp);
        } catch (error) {
            return null;
        }
    }

    isTokenExpired(token?: string): boolean {
        const authToken = token || this.localStorageService.getToken();

        if (!authToken) {
            return true;
        }

        const date = this.getTokenExpirationDate(authToken);

        if (!date) {
            return true;
        }

        return differenceInMinutes(date, new Date()) <= 0;
    }
}
