import { Injectable } from '@angular/core';
import { Event } from '@app/shared/models/event.model';
import { Filters } from '@app/shared/models/filters.model';
import { format, isSameDay } from 'date-fns';

import * as ics from 'ics';
import { DateArray, EventAttributes } from 'ics';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    constructor() {}

    isSameDay(startDate: string, endDate: string): boolean {
        return isSameDay(new Date(startDate), new Date(endDate));
    }

    filtersToQueryParams(filters: Filters): string {
        let params: Record<string, unknown> = {};
        let queryParams;

        // Pagination
        queryParams = `?page=${filters.page}&limit=${filters.limit}`;

        // Sort
        queryParams = `${queryParams}&sort=${filters.sort}`;

        // Parameters
        if (Object.keys(filters.parameters).length > 0) {
            for (let [key, value] of Object.entries(filters.parameters)) {
                if (value !== null && value !== '') {
                    let operator;

                    switch (typeof value) {
                        case 'string':
                            operator = '_contains';
                            break;
                        case 'number':
                            operator = '_eq';
                            break;
                    }

                    if (operator) {
                        params[key] = {
                            [operator]: value,
                        };
                    }

                    if (key === 'my_events') {
                        params.events_users = {
                            directus_users_id: {
                                _eq: '$CURRENT_USER',
                            },
                        };
                    }

                    if (key === 'created_at') {
                        params[key] = {
                            _gt: value,
                        };
                    }
                }
            }

            if (Object.keys(params).length > 0) {
                queryParams = `${queryParams}&filter=${JSON.stringify(params)}`;
            }
        }

        return queryParams;
    }

    createICSFile(event: Event): void {
        const fileName = `${event.name} - ${format(
            new Date(event.start_date),
            'dd MM yyyy'
        )}`;
        const eventAttributes: EventAttributes = {
            start: this.toDateArray(new Date(event.start_date)),
            end: this.toDateArray(new Date(event.end_date)),
            title: event.name,
            description: event.text.replace(/(<([^>]+)>)/gi, ''),
            location: `${event.location_address || ''} ${
                event.location_house_number || ''
            } ${event.location_cap || ''} ${event.location_city || ''} ${
                event.location_province ? `(${event.location_province})` : ''
            }`,
            url: window.location.href,
        };

        ics.createEvent(eventAttributes, (error, value) => {
            if (error) {
                console.log(error);
                return;
            }

            this.createAndDownloadFile(value, fileName, 'text/calendar');
        });
    }

    createAndDownloadFile(data: string, filename: string, type: string): void {
        const file = new Blob([data], { type: type });
        let winNav: any = window.navigator;
        if (winNav && winNav['msSaveOrOpenBlob']) {
            winNav['msSaveOrOpenBlob'](file, filename);
        } else {
            const a = document.createElement('a'),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    }

    toDateArray(date: Date): DateArray {
        return [
            parseInt(format(date, 'yyyy')),
            parseInt(format(date, 'MM')),
            parseInt(format(date, 'dd')),
            parseInt(format(date, 'HH')),
            parseInt(format(date, 'mm')),
        ];
    }

    async shareElement(element: Record<string, string>): Promise<void> {
        const navigator = window.navigator as any;

        if (navigator.share) {
            const text = new DOMParser().parseFromString(
                element.text as string,
                'text/html'
            );
            let shareData = {
                title: element.name,
                text: text.body.textContent?.trim(),
                url: element.url
                    ? this.setValidUrl(element.url)
                    : window.location.href,
            };
            try {
                await navigator.share(shareData);
            } catch (err) {
                console.log(err);
            }
        }
    }

    setValidUrl(link: string): string {
        if (link && link.search(/^http[s]?\:\/\//) === -1) {
            link = 'https://' + link;
        }

        return link;
    }

    goToExternalUrl(url: string): void {
        window.open(this.setValidUrl(url), '_blank');
    }

    //
    // ─── PWA UTILS - METHODS ────────────────────────────────────────────────────────
    //

    isInStandaloneMode(): boolean {
        return window.matchMedia('(display-mode: standalone)').matches;
    }
}
