import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { Modal } from '@app/shared/models/modal.model';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    private modalId = 'errorModal';

    private openModalErrorSource = new Subject<Modal>();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public openModalError$ = this.openModalErrorSource.asObservable();

    constructor() { }

    openModalError(error: unknown, message = ''): void {
        this.openModalErrorSource.next({ modalId: this.modalId, error: error, message: message });
    }
}
