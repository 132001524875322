<div class="afy-head" *ngIf="!isLoginPage">
    <div class="afy-wrapper -row">
        <a class="afy-head__logo" href="#">
            <img
                class="afy-head__logoImage"
                src="./assets/images-opt/logos/logo-hifed.svg"
                width="95"
                height="43"
                alt="Logo Hi Fed!"
            />
        </a>
        <img
            class="afy-head__logoAltea"
            src="./assets/images-opt/logos/logo-altea-federation.svg"
            width="74"
            height="37"
            alt="Logo Altea"
        />
        <div class="afy-head__nav">
            <div class="afy-btn -tab" routerLinkActive="-active">
                <a class="afy-btn__element" [routerLink]="['/news']">
                    <i class="afy-btn__icon icon-news"></i>
                    <span class="afy-btn__text">notizie</span>
                </a>
            </div>
            <div class="afy-btn -tab" routerLinkActive="-active">
                <a class="afy-btn__element" [routerLink]="['/events']">
                    <i class="afy-btn__icon icon-calendar"></i>
                    <span class="afy-btn__text">Eventi</span>
                </a>
            </div>
            <div class="afy-btn -tab" routerLinkActive="-active">
                <a class="afy-btn__element" [routerLink]="['/bookings']">
                    <i class="afy-btn__icon icon-desk"></i>
                    <span class="afy-btn__text">prenota</span>
                </a>
            </div>
            <div class="afy-btn -tab" routerLinkActive="-active">
                <a class="afy-btn__element" [routerLink]="['/surveys']">
                    <i class="afy-btn__icon icon-announcement-speaker"></i>
                    <span class="afy-btn__text">dì la tua</span>
                </a>
            </div>
        </div>
        <a
            class="afy-head__bell"
            [class.-active]="unreadNotifications > 0"
            [routerLink]="['/notifications']"
            routerLinkActive="-active"
        >
            <span class="afy-head__bellBadge" *ngIf="unreadNotifications > 0"
                >{{ unreadNotifications }}
                <sup *ngIf="unreadNotifications > 9">+</sup></span
            >
            <i class="afy-head__bellIcon icon-notification"></i>
        </a>
        <div class="afy-btn -basic -wiki" routerLinkActive="-active">
            <a class="afy-btn__element" [routerLink]="['/wiki']">
                <i class="afy-btn__icon icon-hamburger-menu"></i>
                <span class="afy-btn__text">Wiki</span>
            </a>
        </div>
    </div>
</div>

<div class="afy-wrapper -head" *ngIf="isLoginPage">
    <div class="afy-login__head">
        <a class="afy-login__logo" href="#">
            <img
                class="afy-login__logoImg"
                src="./assets/images-opt/logos/logo-hifed.svg"
                width="95"
                height="43"
                alt="logo Hi Fed!"
            />
        </a>
        <div class="afy-login__altea">
            <img
                class="afy-login__alteaLogo"
                src="./assets/images-opt/logos/logo-altea-federation.svg"
                width="65"
                height="32"
                alt="logo altea federation"
            />
            <div class="afy-login__alteaText">By</div>
            <img
                class="afy-login__hnrgLogo"
                src="./assets/images-opt/logos/logo-hnrg.svg"
                width="33"
                height="10"
                alt="Logo HNRG"
            />
        </div>
    </div>
</div>
