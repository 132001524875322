import { Injectable } from '@angular/core';

import { ModalComponent } from '@app/shared/components/modal/modal.component';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private modals: ModalComponent[] = [];

    constructor() {}

    registerModal(newModal: ModalComponent): void {
        const modal = this.findModal(newModal.modalId);

        if (modal) {
            this.modals.splice(this.modals.indexOf(modal), 1);
        }

        this.modals.push(newModal);
    }

    open(modalId: string): void {
        const modal = this.findModal(modalId);
        if (modal) {
            modal.open();
        }
    }

    close(modalId: string, checkBlocking = false): void {
        const modal = this.findModal(modalId);

        if (modal) {
            if (checkBlocking && modal.blocking) {
                return;
            }
            modal.close(undefined);
        }
    }

    private findModal(modalId: string): ModalComponent | null {
        for (const modal of this.modals) {
            if (modal.modalId === modalId) {
                return modal;
            }
        }
        return null;
    }
}
