import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
    Component,
    OnInit,
    Input,
    ElementRef,
    Output,
    EventEmitter,
    Inject,
    PLATFORM_ID,
    OnDestroy,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
} from '@angular/core';

import { ModalService } from '@app/core/services/base/modal.service';

import { ModalConfig, DesktopAlign, MobileAlign, ScrollOrigin, Animation, Width } from '@app/shared/components/modal/modal-config.model';

@Component({
    selector: 'afy-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnChanges, OnDestroy {
    public popupClasses!: string[];
    public popupMaxWidth!: string;

    private openTimeout!: any;
    private closeTimeout!: any;

    @Input() modalId!: string;
    @Input() modalTitle!: string;
    @Input() modalSubtitle!: string;
    @Input() config: ModalConfig = new ModalConfig();
    @Input() blocking = false;
    @Input() openAfterInitialization = false;
    @Output() afterOpen = new EventEmitter<any>();

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private elementRef: ElementRef,
        private modalService: ModalService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: any
    ) { }

    ngOnInit(): void {
        this.modalService.registerModal(this);
    }

    open(): void {
        const popup = this.elementRef.nativeElement.getElementsByClassName('afy-popup')[0];
        const contentPopup = this.elementRef.nativeElement.getElementsByClassName('afy-popup__content')[0] as HTMLElement;
        const otherNavigationItem = document.querySelector('.afy-nav__item.-other') as HTMLElement;

        this.popupClasses = ['afy-popup'];

        this.initPopup(popup, contentPopup);
        this.changeDetectorRef.detectChanges();

        this.toggleBodyOverflow();

        contentPopup.style.display = 'flex';
        popup.classList.add('-show');
        popup.setAttribute('aria-hidden', 'false');

        const popupTransitionDuration = this.transitionDuration(popup);

        this.openTimeout = setTimeout(() => {
            popup.classList.add('-open');
            if (this.config.fromMenu) {
                otherNavigationItem.classList.add('-current');
            }
            this.afterOpen.emit();
        }, popupTransitionDuration);
    }

    close(event?: any): void {
        const popup = this.elementRef.nativeElement.getElementsByClassName('afy-popup')[0];
        const contentPopup = this.elementRef.nativeElement.getElementsByClassName('afy-popup__content')[0] as HTMLElement;
        if (event) {
            const clickToClose =
                (event.target === popup && event.target !== contentPopup) || (event.type !== 'click' && event.key === 'Escape');

            if (clickToClose) {
                this.closePopup(popup, contentPopup);
            }
        } else {
            this.closePopup(popup, contentPopup);
        }
    }

    closePopup(popup: any, contentPopup: any): void {
        popup.classList.remove('-open');
        popup.style.transitionDuration = '0.22s';

        const popupTransitionDuration = this.transitionDuration(popup);
        const otherNavigationItem = document.querySelector('.afy-nav__item.-other') as HTMLElement;

        this.closeTimeout = setTimeout(() => {
            popup.classList.remove('-show');
            contentPopup.style.display = 'none';
            this.document.body.style.paddingRight = '0';
            this.document.body.classList.remove('-hideOverflow');
            if (this.config.fromMenu) {
                otherNavigationItem.classList.remove('-current');
            }
        }, popupTransitionDuration);

        popup.setAttribute('aria-hidden', 'true');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.openAfterInitialization && changes.openAfterInitialization.currentValue === true) {
            this.open();
        }
    }

    ngOnDestroy(): void {
        if (this.openTimeout) {
            clearTimeout(this.openTimeout);
        }
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
        }
    }

    private toggleBodyOverflow(): void {
        if (isPlatformBrowser(this.platformId)) {
            const scrollbarWidth = window.innerWidth - this.document.documentElement.clientWidth;
            this.document.body.classList.add('-hideOverflow');

            if (scrollbarWidth > 0) {
                this.document.body.style.paddingRight = scrollbarWidth + 'px';
            }
        }
    }

    private transitionDuration(target: Element): number {
        if (isPlatformBrowser(this.platformId)) {
            const getTransitionDuration = window.getComputedStyle(target).getPropertyValue('transition-duration');
            const calculateTransitionDuration = parseInt(getTransitionDuration.substring(0, getTransitionDuration.length - 1), 10) * 1000;
            return calculateTransitionDuration;
        } else {
            return 400;
        }
    }

    private initPopup(popup: any, contentPopup: any): void {
        switch (this.config.width) {
            case Width.normal:
                this.popupMaxWidth = '456px';
                break;
            case Width.medium:
                this.popupMaxWidth = '584px';
                break;
            case Width.large:
                this.popupMaxWidth = '720px';
                break;
            default:
                this.popupMaxWidth = '584px';
        }

        switch (this.config.desktopAlign) {
            case DesktopAlign.top:
                this.popupClasses.push('-desktopTop');
                break;
            case DesktopAlign.center:
                this.popupClasses.push('-desktopCenter');
                break;
            case DesktopAlign.bottom:
                this.popupClasses.push('-desktopBottom');
                break;
            default:
                this.popupClasses.push('-desktopTop');
                break;
        }

        switch (this.config.mobileAlign) {
            case MobileAlign.top:
                this.popupClasses.push('-mobileTop');
                break;
            case MobileAlign.center:
                this.popupClasses.push('-mobileCenter');
                break;
            case MobileAlign.bottom:
                this.popupClasses.push('-mobileBottom');
                break;
            default:
                this.popupClasses.push('-mobileTop');
                break;
        }

        switch (this.config.scrollOrigin) {
            case ScrollOrigin.page:
                this.popupClasses.push('-scrollPage');
                break;
            case ScrollOrigin.modal:
                this.popupClasses.push('-scrollModal');
                break;
            default:
                this.popupClasses.push('-scrollPage');
                break;
        }

        if (this.config.scrollOrigin === ScrollOrigin.modal && isPlatformBrowser(this.platformId)) {
            const popupPaddingTop = window.getComputedStyle(popup).getPropertyValue('padding-top');
            const popupPaddingBottom = window.getComputedStyle(popup).getPropertyValue('padding-bottom');

            contentPopup.style.maxHeight = 'calc(100vh - ' + popupPaddingTop + ' - ' + popupPaddingBottom + ')';
            contentPopup.children[0].style.maxHeight = 'calc(100vh - ' + popupPaddingTop + ' - ' + popupPaddingBottom + ')';
        }

        switch (this.config.fullSize) {
            case false:
                this.popupClasses.push('-noFullSize');
                break;
            case true:
                this.popupClasses.push('-fullSize');
                break;
            default:
                this.popupClasses.push('-noFullSize');
                break;
        }

        switch (this.config.mobileFullSize) {
            case false:
                this.popupClasses.push('-mobNoFullSize');
                break;
            case true:
                this.popupClasses.push('-mobFullSize');
                break;
            default:
                this.popupClasses.push('-mobNoFullSize');
                break;
        }

        switch (this.config.animation) {
            case Animation.fadeIn:
                this.popupClasses.push('-fadeIn');
                break;
            case Animation.fadeInUp:
                this.popupClasses.push('-fadeInUp');
                break;
            case Animation.fadeInDown:
                this.popupClasses.push('-fadeInDown');
                break;
            case Animation.zoomIn:
                this.popupClasses.push('-zoomIn');
                break;
            default:
                this.popupClasses.push('-fadeIn');
                break;
        }
    }
}
