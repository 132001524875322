import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { Notifications } from '@app/shared/models/notification.model';
@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    private basePath = 'hifed/';
    private unreadNotificationsFlagSource = new Subject<boolean>();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public unreadNotificationsFlag$ = this.unreadNotificationsFlagSource.asObservable();

    constructor(
        private httpClient: HttpClient
    ) { }

    getNotifications(queryParams: string = ''): Observable<Notifications> {
        const url = `${this.basePath}items/notifications${queryParams}`;

        return this.httpClient.get<Notifications>(url);
    }

    checkNewNotifications(): Observable<number> {
        const url = `${this.basePath}items/notifications/unread`;

        return this.httpClient.get<number>(url);
    }

    setNotificationAsRead(
        params: Record<string, unknown>
    ): Observable<Record<string, unknown>> {
        const url = `${this.basePath}items/notifications_directus_users`;

        return this.httpClient.post<Record<string, unknown>>(url, params);
    }

    setAllNotificationAsRead(): Observable<Record<string, any>> {
        const url = `${this.basePath}items/notifications/read-all`;

        return this.httpClient.post<Record<string, any>>(url, {});
    }

    //
    // ─── RXJS ───────────────────────────────────────────────────────────────────────
    //

    updateUnreadNotificationsFlag(flag: boolean): void {
        this.unreadNotificationsFlagSource.next(flag);
    }
}
