import { Component } from '@angular/core';

@Component({
    selector: 'afy-layout-login-page',
    template: `
        <router-outlet></router-outlet>
    `,
    styles: []
})
export class LayoutLoginPageComponent {

    constructor() { }
}
