import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';

import * as Sentry from '@sentry/angular-ivy';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { BookingsModule, ExploreModule } from '@worthspace/booking';

registerLocaleData(localeIt);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.serviceWorker,
            registrationStrategy: 'registerWhenStable:30000',
        }),
        BookingsModule.forRoot({
            apiUrl: `${environment.apiUrl}/`,
            token: localStorage.getItem('TOKEN') || '',
        }),
        ExploreModule.forRoot({
            apiUrl: `${environment.apiUrl}/`,
            token: localStorage.getItem('TOKEN') || '',
        }),
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'it',
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
