import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { tap, finalize, catchError, switchMap } from 'rxjs/operators';

import { AuthService } from '@app/core/services/base/auth.service';
import { LocalStorageManagementService } from '@app/core/services/base/local-storage-management.service';

import { AuthResponse } from '@app/shared/models/auth-response.model';
import { Constants } from '@app/core/constants/constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private authService!: AuthService;
    private refreshTokenInProgress = false;

    private tokenRefreshedSource = new Subject<AuthResponse>();
    private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

    constructor(
        private injector: Injector,
        private localStorageService: LocalStorageManagementService,
        private router: Router
    ) {}

    addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
        if (this.localStorageService.getItem(Constants.TOKEN)) {
            return request.clone({
                setHeaders: {
                    Authorization:
                        'Bearer ' +
                        this.localStorageService.getItem(Constants.TOKEN),
                },
            });
        }

        return request;
    }

    refreshToken(): Observable<AuthResponse> {
        if (this.refreshTokenInProgress) {
            return new Observable((observer: any) => {
                this.tokenRefreshed$.subscribe((response: AuthResponse) => {
                    observer.next(response);
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;

            return this.authService.refreshToken().pipe(
                tap((response: AuthResponse) => {
                    this.tokenRefreshedSource.next(response);
                }),
                finalize(() => (this.refreshTokenInProgress = false))
            );
        }
    }

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        this.authService = this.injector.get(AuthService);
        this.localStorageService = this.injector.get(
            LocalStorageManagementService
        );

        if (
            !request.headers.get('Authorization') &&
            !request.url.includes('refresh')
        ) {
            request = this.addAuthHeader(request);
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (
                    error.status === 401 &&
                    !request.url.includes('login') &&
                    !request.url.includes('logout') &&
                    !request.url.includes('refresh') &&
                    !request.url.includes('oauth')
                ) {
                    return this.refreshToken().pipe(
                        tap((response: AuthResponse) => {
                            this.localStorageService.setToken(response.token);
                        }),
                        catchError(() => {
                            this.localStorageService.clearLocalStorage();
                            this.router.navigate(['/login']);
                            return EMPTY;
                        }),
                        switchMap(() => {
                            request = this.addAuthHeader(request);
                            return next.handle(request);
                        }),
                        finalize(() => {
                            this.refreshTokenInProgress = false;
                        })
                    );
                }
                return throwError(error);
            })
        ) as Observable<HttpEvent<unknown>>;
    }
}
