<router-outlet></router-outlet>

<afy-modal [modalId]="'iOSPWAInstall'" [config]="iOSPWAModalConfig">
    <div class="afy-pwaInstall">
        <div class="afy-pwaInstall__head">
            <h3 class="afy-pwaInstall__headTitle">Vuoi installare l'App sul telefono?</h3>
        </div>
        <div class="afy-pwaInstall__content">
            <p class="afy-pwaInstall__text">
                Tocca
                <span class="afy-pwaInstall__textIconWrap -options">
                    <i class="afy-pwaInstall__textIcon icon-ios-share"></i>
                </span>
                e poi<strong>"Aggiungi a Home
                    <span class="afy-pwaInstall__textIconWrap -addToHome">
                        <i class="afy-pwaInstall__textIcon icon-ios-add"></i>
                    </span>"</strong>
                Per installare l'App sul tuo iPhone
            </p>
        </div>
    </div>
</afy-modal>
