import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { ErrorService } from '@app/core/services/base/error.service';
import { NotificationsService } from '@app/core/services/notifications.service';
@Component({
    selector: 'afy-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    public unreadNotificationsFlag!: Subscription;
    public routerSubscription!: Subscription;
    public unreadNotifications!: number;

    @Input() isLoginPage!: boolean;

    constructor(
        private notificationsService: NotificationsService,
        private errorService: ErrorService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.listenForRouteChange();
        this.listenForUnreadNotificationsFlagChange();
        this.checkNewNotifications();
    }

    checkNewNotifications(): void {
        this.notificationsService.checkNewNotifications().subscribe(
            (response: number) => {
                this.unreadNotifications = response;
            },
            (error: HttpErrorResponse) => {
                this.errorService.openModalError(error, 'Errore nel check delle notifiche da leggere');
            })
    }

    //
    // ─── SUBSCRIPTION - METHODS ─────────────────────────────────────────────────────
    //


    listenForRouteChange(): void {
        this.routerSubscription = this.router.events.subscribe((instance: Event) => {
            if (instance instanceof NavigationEnd) {
                this.checkNewNotifications();
            }
        });
    }

    listenForUnreadNotificationsFlagChange(): void {
        this.unreadNotificationsFlag = this.notificationsService.unreadNotificationsFlag$.subscribe((flag: boolean) => {
            this.checkNewNotifications();
        });
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
        this.unreadNotificationsFlag.unsubscribe();
    }
}
